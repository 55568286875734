import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";


import {
  DateRangePicker
} from "../../../components/uielements/reactDates";
import configs from "./config";

class ReactDatesTopbar extends Component {
  constructor(props) {
    super(props);
    this.renderDatePicker = this.renderDatePicker.bind(this);

    let start     = localStorage.getItem('startDate') === null ?
        moment(new Date()).subtract(7,'days') :
        moment(localStorage.getItem('startDate'));

    let end       = localStorage.getItem('endDate') === null ?
        moment(new Date()) :
        moment(localStorage.getItem('endDate'));


    localStorage.setItem('startDate', start);
    localStorage.setItem('endDate', end);

    this.state = {
      configsValue: configs,
      singleFocused: true,
      singleResult: null,
      focusedInput: null  ,
      startDate: start,
      endDate: end,
    };
  }

  renderDatePicker() {
    const {
      startDate,
      endDate,
      focusedInput,
      configsValue
    } = this.state;

    let options = {
      startDate,
      endDate,
      startDateId:'startDate123456789',
      endDateId:'endDate123456789',
      onDatesChange: ({ startDate, endDate }) =>  {
        localStorage.setItem('startDate', startDate);
        localStorage.setItem('endDate', endDate);
        this.setState({ startDate, endDate });
      },
      focusedInput,
      onFocusChange: focusedInput => {

        if (focusedInput === null) {
          window.location.reload();
        }
        this.setState({ focusedInput });
      }
    };

    const renderOptions = configsValue[1].options;
    renderOptions.forEach(option => {
      options[option.id] = option.value;
    });

    if (this.props.view === "MobileView") {
      options.numberOfMonths = 1;
    }

    return (
      <div className="isoReactDate">
        <DateRangePicker minimumNights={0} isOutsideRange={() => false} {...options} />
      </div>
    );
  }

  render() {
    // const { rowStyle, colStyle, gutter } = basicStyle;
    return (
        this.renderDatePicker(configs[1])
    );
  }
}
export default connect(state => ({
  view: state.App.view
}))(ReactDatesTopbar);
