import React, { createRef } from "react";
import styled from "styled-components";
import { Alert, Button } from "antd";
import full_logo from "../../image/SKYCALL-LOGOSOMBRE.png";
import BugButton from "../../customApp/components/bugButton";

class AppErrorBoundary extends React.Component {
  state = { error: null, errorInfo: null }
  constructor(props) {
    super(props);
    this.mainRef = createRef()
  }

  componentDidCatch (error, errorInfo) {
    this.setState({
      error: error.message,
      errorInfo: errorInfo,
    });
  }

  render () {
    if (this.state.error) {
      return (
        <Container>
          <Wrapper>
            <Logo src={full_logo} alt="Skycall Logo" />
            <Alert
              type="error"
              style={{ marginTop: "48px" }}
              message={<Title style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5em' }}>
                <img src="/images/error-icon.png" height="60px" alt="Error icon" />
                <span style={{ marginLeft: '10px' }}>Erreur</span>
              </Title>}
              description={
                <div ref={this.mainRef} style={{ textAlign: 'center' }}>
                  <Message>
                    Oups, une erreur s&apos;est produite lors de l&apos;affichage de cette page.<br />
                    Pour continuer, cliquez sur Rafraîchir<br /><br />
                    <Button onClick={this.handleClick} type="primary">
                      Rafraîchir
                    </Button><br /><br />
                    <span style={{ fontSize: '0.9em' }}>Vous pouvez informer le propriétaire du site du problème en cliquant ici : </span>

                  </Message>
                  <BugButton payload={this.state} refToShoot={this.mainRef.current} title="Envoyer un rapport de Bug" />
                </div>
              }
            />
          </Wrapper>
        </Container>
      );
    }
    return this.props.children;
  }

  handleClick = () => {
    window.location.href = "/";
  };
}

const Container = styled.div`
  min-height: 100vh;
  padding-top: 72px;
  background: rgb(12, 0, 50);
`;

const Wrapper = styled.div`
  margin: auto;
  max-width: 700px;
  padding: 0 24px;
`;

const Logo = styled.img`
  display: block;
  max-width: 250px;
`;

const Title = styled.h1`
  font-size: 1.2em;
`;

const Message = styled.p`
  padding: 16px 0;
`;


export default AppErrorBoundary;
