import antdEn from 'antd/lib/locale-provider/en_US';
import defaultMessages from '../locales/theme/en_US.json';
import leadMessages from '../locales/lead/en_US.json';
import sidebarMessages from '../locales/menu/en_US.json';
import subscriptionMessages from '../locales/subscription/en_US.json';
import scenarioMessages from '../locales/scenario/en_US.json';
import merMessages from '../locales/mer/en_US.json';
import skytrustMessages from '../locales/skytrust/en_US.json';

const EnLang = {
  messages: {
    ...defaultMessages,
    ...leadMessages,
    ...sidebarMessages,
    ...subscriptionMessages,
    ...scenarioMessages,
    ...merMessages,
    ...skytrustMessages,
  },
  antd: antdEn,
  locale: 'en-US',
  //data: appLocaleData,
};
export default EnLang;
