import Backend from '../backend/backend'

export default class Cdn extends Backend {
  constructor() {
    super()
    this.switchToGcfUrl()
  }

  async createCdn (params) {
    const headers = {}
    if (params.apiKey && params.apiKey.length > 1) {
      headers['x-api-key'] = params.apiKey
      delete params.apiKey
    }
    return this.request('post', 'cdn/', params, headers).then(res => {
      if (res.data.status === 'ok') {
        return res.data
      }
      return null
    })
  }
}
