import axios from 'axios';
import env from "../../../settings"
import moment from "moment";
import history from '../../../history'
import { clearToken } from '../../../../src/helpers/utility'

class Backend {
    rootUrl = env.apiUrl
    cacheControl = null
    setCacheControl (value) {
        this.cacheControl = value
    }
    switchToGcfUrl () {
        this.rootUrl = env.gcfUrl
    }

    switchToApolloUrl () {
        this.rootUrl = env.apolloUrl
        this.cacheControl = 'no-store'
    }
    switchToSkyRefUrl () {
        this.rootUrl = env.skyrefUrl
        this.cacheControl = 'no-store'
    }
    switchToIpfyUrl () {
        this.rootUrl = 'https://api.my-ip.io/ip.json'
    }
    switchToSmsPlusUrl () {
        this.rootUrl = env.smsPlusUrl
    }

    request (method, service, parameters, headers = {}) {
        const token = localStorage.getItem('token');
        const apiKey = localStorage.getItem('api_key');

        if (token) {
            headers = { ...headers }
            if (token && !apiKey) {
                headers['x-access-token'] = token;
            } else if (apiKey) {
                headers['x-api-key'] = apiKey;

            }
        }
        console.log("ROOT URL : ", this.rootUrl)
        if (this.cacheControl) {
            headers['Cache-Control'] = this.cacheControl
        }

        const axioParams = {
            method: method,
            url: this.rootUrl + ((service) ? '/' + service : ''),
            headers: headers
        }

        if (method.toLowerCase() === 'get') {
            axioParams.params = parameters;
        } else {
            axioParams.data = parameters;
        }

        return axios(axioParams)
            .catch(error => {
                if (error.response && error.response.status === 400 && this.rootUrl.endsWith('graphql')) {//graphql ERROR
                    if (error.response.data && error.response.data?.errors[0]?.extensions?.code === 'UNAUTHENTICATED') {
                        history.push('/')
                        history.go()
                        return Promise.resolve({ data: {} })
                    }

                }
                if (error.response && error.response.status === 401) {
                    clearToken()
                    history.push('/')
                    history.go()
                    return Promise.resolve({ data: {} })
                }

                if (error.response && error.response.status === 403) {
                    history.push('/403')
                    history.go()
                    return Promise.resolve({ data: {} })
                }
                if (error.response && error.response.status === 500) {
                    history.push('/500')
                    history.go()
                    return Promise.resolve({ data: {} })
                }
                return Promise.reject(error)
            }

            )

    }

    requestFile (method, service, parameters, headers = {}) {
        const token = localStorage.getItem('token');
        const apiKey = localStorage.getItem('api_key');

        if (token) {
            headers = { ...headers }
            if (token && !apiKey) {
                headers['x-access-token'] = token;
            } else if (apiKey) {
                headers['x-api-key'] = apiKey;

            }
        }

        return axios({
            method: method,
            url: this.rootUrl + ((service) ? '/' + service : ''),
            headers: headers,
            data: parameters,
            responseType: "blob"
        });
    }

    async profile () {
        return this.request('get', 'account/me', {}).then(res => {
            if (res.data.status === 'ok') {
                localStorage.setItem('profile', JSON.stringify(res.data));
                // console.log(res.data.callcenters, res.data.groups.length)
                localStorage.setItem('callcenters', res.data.callcenters || '');
                localStorage.setItem('services', (res.data.groups && res.data.groups.length) ? res.data.groups.filter(d => d.services.length).map(d => d.services.join(',')).join(',') : '')
            }
            return res.data;
        });
    }

    login (login, password) {
        return this.request('post', 'auth/login', {
            'login': login.trim(),
            'password': password.trim(),
        }).then(res => {
            res = res.data;

            if (res.auth) {
                this.setStorage(res);

            }
            return res;
        });
    }

    loginAs (apiKey) {
        localStorage.setItem("api_key", apiKey);
        return this.profile()
            .then((profile) => {
                let start = moment(new Date()).subtract(7, 'days');
                let end = moment(new Date());
                localStorage.setItem('startDate', start);
                localStorage.setItem('endDate', end);
                localStorage.setItem('role', profile.role);
                localStorage.setItem('products', profile.products || []);
                localStorage.setItem('expire', new Date().getTime() + 7200000);

            });
    }

    setStorage (res) {
        let start = moment(new Date()).subtract(7, 'days');
        let end = moment(new Date());

        localStorage.setItem('startDate', start);
        localStorage.setItem('endDate', end);

        localStorage.setItem('token', res.token);
        localStorage.setItem('id_token', res.token);
        localStorage.setItem('role', res.role);
        localStorage.setItem('products', res.products || []);

        localStorage.setItem('expire', new Date().getTime() + 14000000);



        if (res.tellerbox !== undefined) {
            if (res.role === 'merchant') {
                localStorage.setItem('role', 'merchant+teller');
            } else if (res.role === 'operator') {
                localStorage.setItem('role', 'operator+teller');
                res.role = 'operator+teller';
            } else if (res.role === 'affiliate') {
                localStorage.setItem('role', 'affiliate+teller');
                res.role = 'affiliate+teller';
            }
            localStorage.setItem('tellerbox', JSON.stringify(res.tellerbox));
            localStorage.setItem('tellerbox.token', res.tellerbox.token);

            localStorage.setItem('tellerbox.default', res.tellerbox.platform[0].name);
            localStorage.setItem('tellerbox.client', res.tellerbox.platform[0].data.client_id);
            localStorage.setItem('tellerbox.platform', res.tellerbox.platform[0].data.platform_id);
            localStorage.setItem('tellerbox.callcenter', res.tellerbox.platform[0].data.callcenter_id);

        }
    }

    async listCall () {
        return this.request('post', 'call/', {})
            .then(res => {
                res = res.data;
                return res;
            })
            .catch((err) => {
                return {
                    'status': 'err',
                    'error': err
                }
            });
    }
}

export default Backend;
