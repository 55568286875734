import React from 'react';
import 'react-dates/initialize'
import { Provider } from 'react-redux';
import { store } from './redux/store';
import PublicRoutes from './router';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import themes from './settings/themes';
import AppLocale from './languageProvider';
import config, {
  getCurrentLanguage
} from './containers/LanguageSwitcher/config';
import { themeConfig } from './settings';
import DashAppHolder from './dashAppStyle';
import Boot from './redux/boot';
import ConnectedIntlProvider from './connectedIntlProvider';
import Helmet from 'react-helmet'
import { ApolloClient, InMemoryCache, HttpLink, concat, ApolloLink } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { useGA4React } from "ga-4-react";
import AppErrorBoundary from "./components/utility/AppErrorBoundary"

const browserLanguage = navigator.language.split(/[-_]/)[0];
const langKey = ((Object.keys(AppLocale).includes(browserLanguage)) ? getCurrentLanguage(browserLanguage).locale : getCurrentLanguage(config.defaultLanguage || 'english').locale);
const currentAppLocale = AppLocale[langKey];
//console.log(currentAppLocale);
if (currentAppLocale.locale === 'fr-FR') {
  require('moment/locale/' + currentAppLocale.locale.replace('-FR', ''))
}

// const { changeLanguage } = actions;
const defaultTitle = ((/staging/i.test(window.location)) ? 'Staging - SkyCall' : ((/^http[s]?:\/\/(localhost|172.26|192.168)/i.test(window.location)) ? 'Dev - SkyCall' : 'SkyCall'))

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      "x-access-token": localStorage.getItem('token') || null,
    }
  });

  return forward(operation);
})

const httpLink = new HttpLink({ uri: '/graphql' });

const client = new ApolloClient({ uri: process.env.REACT_APP_APOLLO_URL, cache: new InMemoryCache(), link: concat(authMiddleware, httpLink) });

const DashApp = () => {
  useGA4React()
  return (
    <ConnectedIntlProvider store={store}>
      <AppErrorBoundary>
      <ConfigProvider locale={currentAppLocale.antd}>
        <Helmet>
          <title>{defaultTitle}</title>
        </Helmet>
        <ApolloProvider client={client}>
          <ThemeProvider theme={themes[themeConfig.theme]}>
            <DashAppHolder>
              <Provider store={store}>
                <PublicRoutes />
              </Provider>
            </DashAppHolder>
          </ThemeProvider>
        </ApolloProvider>
      </ConfigProvider>
      </AppErrorBoundary>
    </ConnectedIntlProvider>
  )
};
Boot()
  .then(() => DashApp())
  .catch(error => console.error(error));

export default DashApp;
export { AppLocale };
