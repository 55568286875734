import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import customRoutes from '../../customApp/router';
import RouteErrorBoundary from '../../components/utility/RouteErrorBoundary';
import { Spin } from 'antd';

const routes = [
  ...customRoutes,
];

class AppRouter extends Component {
  render () {
    const { url, style } = this.props;

    return (
      <div style={style}>
        <Suspense fallback={<div style={{ width: "100%", "textAlign": "center", height: "100%", "verticalAlign": "middle", paddingTop: "100px" }}><Spin /></div>}>
          <Switch>
            {routes.map(singleRoute => {
              const Screen = singleRoute.component
              return (
                <Route
                  exact={singleRoute.exact === false ? false : true}
                  key={singleRoute.path}
                  path={`${url}/${singleRoute.path}`}
                  render={(props2) => (<RouteErrorBoundary><Screen {...this.props} {...props2} /></RouteErrorBoundary>)}
                />
              );
            })}
          </Switch>
        </Suspense>
      </div>
    );
  }
}

export default AppRouter;
