import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Menu, Dropdown } from "antd";
import appActions from "../../redux/app/actions";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import flagUk from '../../image/flag/uk.svg';
import flagFr from '../../image/flag/france.svg';
import actions from '../../redux/languageSwitcher/actions';
import ReactDateTopBarfrom from "../../containers/AdvancedUI/ReactDates/reactDatesTopbar";
const { changeLanguage } = actions;
const { Header } = Layout;
const { toggleCollapsed } = appActions;


class Topbar extends Component {
  state = {
    profile: {},
    role: ''
  }

  constructor(props) {
    super(props);
    this.state = {
      role: localStorage.getItem('role'),
      profile: JSON.parse(localStorage.getItem('profile'))
    }

  }


  render () {
    const { toggleCollapsed, customizedTheme, languageSwitcher, changeLanguage } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;

    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };

    /*const listStyle = {
      display: 'inline',
      marginLeft: '20px',
      color: this.state.color
    };*/

    const menu = (
      <Menu>
        <Menu.Item>
          <img src={flagUk} alt="English" style={{ width: "30px" }} onClick={() => {
            changeLanguage('english');
            this.props.history.push('/private');
          }} />
        </Menu.Item>
        <Menu.Item>
          <img src={flagFr} alt="Fran?ais" style={{ width: "30px" }} onClick={() => {
            changeLanguage('french');
            this.props.history.push('/private');
          }} />
        </Menu.Item>
      </Menu>
    );

    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            <button
              className={
                collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
              }
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
            />

          </div>

          <ul className="isoRight">
            <li>
              <ReactDateTopBarfrom />
            </li>
            <li>
              <Dropdown overlay={menu} placement="bottomCenter">
                <img src={(languageSwitcher.language.locale === 'fr') ? flagFr : flagUk} alt={languageSwitcher.language.languageId} style={{ width: "30px" }} />
              </Dropdown>
            </li>
            <li>
              {(this.state.profile?.firstname || '') + ' ' + (this.state.profile?.lastname || '')}
            </li>
            {/* <li
              onClick={() => this.setState({ selectedItem: "message" })}
              className="isoMsg"
            >
              <TopbarMessage locale={languageSwitcher.language.locale} />
            </li> */}

            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser locale={languageSwitcher.language.locale} />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    languageSwitcher: state.LanguageSwitcher,
    locale: state.LanguageSwitcher.language.locale,
    customizedTheme: state.ThemeSwitcher.topbarTheme
  }),
  { toggleCollapsed, changeLanguage }
)(Topbar);
