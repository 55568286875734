const options = [
    {
        key: '',
        label: 'sidebar.dashboard',
        leftIcon: 'ion-grid',
        role: ['admin', 'merchant', 'affiliate', 'merchant+teller', 'affiliate+teller', 'supervisor']
    },
    {
        key: 'realtime',
        label: 'sidebar.realtime',
        leftIcon: 'ion-ios-alarm',
        role: ['admin'],
        product: '118'
    },
    {
        key: 'operator/activity',
        label: 'sidebar.activity',
        leftIcon: 'ion-ios-color-wand',
        role: ['admin', 'operator']
    },
    {
        key: 'stats',
        label: 'sidebar.statistics',
        leftIcon: 'ion-stats-bars',
        role: ['affiliate', 'admin', 'merchant', 'analyst', 'merchant+teller', 'affiliate+teller'],
    },
    {
        key: 'timeline',
        label: 'sidebar.timeline',
        leftIcon: 'ion-stats-bars',
        role: ['admin'],
    },
    {
        key: '118',
        label: 'sidebar.118',
        leftIcon: 'ion-ios-people',
        role: ['affiliate', 'admin', 'affiliate+teller', 'merchant'],
        product: '118',
        children: [
            {
                key: 'affiliate/stats',
                label: 'sidebar.statistics_aff',
                role: ['affiliate', 'admin', 'affiliate+teller', 'merchant'],
                product: '118',
            },
            {
                key: 'affiliate/stats/keyword',
                label: 'sidebar.affiliate.statsKeyword',
                role: ['affiliate', 'admin', 'affiliate+teller', 'merchant'],
                product: '118',
            },
            /*{
              key: 'affiliates/stats/website',
              label: 'sidebar.affiliate.statsWebsite',
              role: ['affiliate', 'admin'],
            },*/
            {
                key: 'affiliate/stats/theme',
                label: 'table.keyword.themes',
                role: 'admin',
                product: '118',
            },
        ]
    },
    {
        key: 'lead',
        label: 'sidebar.lead',
        leftIcon: 'ion-ios-people',
        role: ['admin', 'merchant', 'affiliate', 'operator', 'supervisor'],
        product: ['lead'],
        children: [
            {
                key: 'lead/statistics',
                label: 'sidebar.leadStats',
                role: ['admin', 'merchant', 'affiliate'],
                product: 'lead',
            },
            {
                key: 'lead/stats',
                label: 'sidebar.leadStatsDyn',
                role: ['admin'],
                product: 'lead',
            },
            {
                key: 'lead/create',
                label: 'sidebar.createLead',
                role: ['admin', 'operator', 'supervisor'],
                product: 'lead',
            },
            {
                key: 'lead/',
                label: 'sidebar.leads',
                role: ['admin', 'operator', 'supervisor'],
                product: 'lead',
            },
            {
                key: 'lead/advertiser',
                label: 'sidebar.advertiser',
                role: ['admin'],
                product: 'lead',
            },
            {
                key: 'lead/offer',
                label: 'sidebar.offers',
                role: ['admin'],
                product: 'lead',
            },
            {
                key: 'lead/publisher',
                label: 'sidebar.publishers',
                role: ['admin'],
                product: 'lead',
            },
            {
                key: 'lead/broker',
                label: 'sidebar.brokers',
                role: ['admin'],
                product: 'lead',
            },
            {
                key: 'lead/trackingAdjust',
                label: 'sidebar.trackingAdjust',
                role: ['admin'],
                product: 'lead',
            },
            {
                key: 'lead/trackingTradeDoubler',
                label: 'sidebar.trackingTradeDoubler',
                role: ['admin'],
                product: 'lead',
            },
            {
                key: 'lead/algorithm',
                label: 'sidebar.algorithm',
                role: ['admin'],
                product: 'lead',
            },
        ]
    },
    /*{
      key: 'server',
      label: 'sidebar.server',
      leftIcon: 'ion-android-desktop',
      role: ['admin']
    },*/
    {
        key: 'serviceMenu',
        label: 'sidebar.service',
        leftIcon: 'ion-android-list',
        role: ['admin'],
        children: [
            {
                key: 'application',
                label: 'sidebar.application',
                role: ['admin'],
            },
            {
                key: 'service',
                label: 'sidebar.service',
                role: ['admin'],
            },
            {
                key: 'office',
                label: 'sidebar.office',
                role: ['admin']
            },
            {
                key: 'call/tag',
                label: 'sidebar.tags',
                role: ['admin']
            },
            {
                key: 'call/tag/stats',
                label: 'sidebar.tagStats',
                role: ['admin']
            },
            {
                key: 'service/tts',
                label: 'sidebar.tts',
                role: ['admin']
            },
        ]
    },
    {
        key: 'operatorsStats',
        label: 'sidebar.operatorsStats',
        leftIcon: 'ion-ios-people',
        role: ['admin', 'supervisor'],
        children: [
            {
                key: 'operatorsStats/agents',
                label: 'sidebar.operatorsStats.agents',
                role: ['admin', 'supervisor'],
            },
            {
                key: 'operatorsStats/supervisors',
                label: 'sidebar.operatorsStats.supervisors',
                role: ['admin', 'supervisor'],
            },
        ]
    },
    {
        key: 'affiliates',
        label: 'sidebar.affiliate',
        leftIcon: 'ion-android-people',
        role: ['admin'],
        children: [
            {
                key: 'affiliates/list',
                label: 'sidebar.affiliate.list',
                role: 'admin',
            },
            {
                key: 'affiliates/keyword',
                label: 'sidebar.affiliate.keyword',
                role: ['admin']
            }
        ],
    },
    {
        key: 'call',
        label: 'sidebar.call',
        leftIcon: 'ion-android-call',
        role: ['admin', 'supervisor'],
        product: '118'
    },
    {
        key: 'message',
        label: 'sidebar.message',
        leftIcon: 'ion-android-mail',
        role: ['admin', 'merchant', 'merchant+teller']
    },
    {
        key: 'carrier',
        label: 'sidebar.carrier',
        leftIcon: 'ion-android-people',
        role: ['admin'],
        children: [
            {
                key: 'carrierIr',
                label: 'sidebar.carrierIr',
                role: ['admin']
            },
            {
                key: 'carrierPayouts',
                label: 'sidebar.carrierPayouts',
                role: ['admin']
            },
            {
                key: 'carrierOutcallFee',
                label: 'sidebar.carrierOutcallFee',
                role: ['admin']
            },
            {
                key: 'chargeback',
                label: 'sidebar.chargeback',
                role: ['admin']
            },
            {
                key: 'chargebackAxialys',
                label: 'sidebar.chargebackAxialys',
                role: ['admin']
            },
        ]
    },
    {
        key: 'companies',
        label: 'sidebar.companies',
        leftIcon: 'ion-android-people',
        role: ['admin']
    },
    {
        key: 'users',
        label: 'sidebar.users',
        leftIcon: 'ion-android-people',
        role: ['admin', 'merchant', 'merchant+teller', 'supervisor'],
    },
    {
        key: 'Finance',
        label: 'sidebar.finance',
        leftIcon: 'ion-clipboard',
        role: ['admin'],
        children: [
            {
                key: 'invoices',
                label: 'sidebar.invoice',
                role: ['admin']
            },
            {
                key: 'missingServices',
                label: 'sidebar.missingServices',
                role: ['admin']
            },
            {
                key: 'notBilled',
                label: 'sidebar.notBilled',
                role: ['admin']
            },
            {
                key: 'margin',
                label: 'sidebar.margin',
                role: ['admin']
            },
            {
                key: 'marginOutcall',
                label: 'sidebar.marginOutcall',
                role: ['admin']
            },
            {
                key: 'margin/affiliates',
                label: 'sidebar.affiliate',
                role: ['admin']
            }
        ]
    },
    {
        key: 'skysearch',
        label: 'sidebar.skysearch',
        leftIcon: 'ion-ios-search',
        role: ['admin', 'supervisor']
    },
    {
        key: 'Scenarios',
        label: 'sidebar.skytouch',
        leftIcon: 'ion-clipboard',
        role: ['admin', 'merchant', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller'],
        product: 'skytouch',
        children: [
            {
                key: 'scenarios/templates',
                label: 'sidebar.templates',
                role: ['admin'],
                product: 'skytouch'
            },
            {
                key: 'scenarios/services',
                label: 'sidebar.services',
                role: ['admin', 'merchant', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller'],
                product: 'skytouch',
            },
            {
                key: 'scenarios/stats',
                label: 'sidebar.stats',
                role: ['admin', 'merchant', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller'],
                product: 'skytouch',
            },
            {
                key: 'scenarios/items',
                label: 'sidebar.items',
                role: ['admin', 'merchant', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller'],
                product: 'skytouch',
            },
            {
                key: 'scenarios/injector',
                label: 'sidebar.injector',
                role: ['admin', 'merchant', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller'],
                product: 'skytouch',
            },
        ]
    },
    {
        key: 'Skytrust',
        label: 'sidebar.skytrust',
        leftIcon: 'ion-clipboard',
        role: ['admin'],
        product: 'skytrust',
        children: [
            {
                key: 'skytrust/form',
                label: 'sidebar.forms',
                role: ['admin'],
                product: 'skytrust'
            },
            {
                key: 'skytrust/theme',
                label: 'sidebar.themes',
                role: ['admin'],
                product: 'skytrust'
            },
            {
                key: 'skytrust/answer',
                label: 'sidebar.answers',
                role: ['admin'],
                product: 'skytrust'
            },
            {
                key: 'skytrust/stats',
                label: 'sidebar.stats',
                role: ['admin'],
                product: 'skytrust'
            },
        ]
    },
    {
        key: 'Mer',
        label: 'sidebar.mer',
        leftIcon: 'ion-code-working',
        role: ['admin', 'merchant', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller'],
        product: 'mer',
        children: [
            {
                key: 'mer/pool',
                label: 'sidebar.pools',
                role: ['admin', 'merchant', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller'],
                product: 'mer',
            },
            {
                key: 'mer/booking',
                label: 'sidebar.bookings',
                role: ['admin', 'merchant', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller'],
                product: 'mer',
            },
            {
                key: 'mer/booking/stats',
                label: 'sidebar.stats',
                role: ['admin', 'merchant', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller'],
                product: 'mer',
            },
            {
                key: 'mer/booking/errors',
                label: 'sidebar.errors',
                role: ['admin'],
                product: 'mer',
            },
        ]
    },
    {
        key: 'Component',
        label: 'sidebar.component',
        leftIcon: 'ion-code-working',
        role: ['admin', 'merchant', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller'],
        product: 'component',
        children: [
            {
                key: 'component/supplier',
                label: 'sidebar.suppliers',
                role: ['admin'],
                product: 'component'
            },
            {
                key: 'component/companyFee',
                label: 'sidebar.companyFees',
                role: ['admin'],
                product: 'component'
            },
            {
                key: 'component/chatbot',
                label: 'sidebar.chatbot',
                role: ['admin', 'merchant', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller'],
                product: 'component'
            },
            {
                key: 'component/email',
                label: 'sidebar.email',
                role: ['admin'],
                product: 'component'
            },
            {
                key: 'component/sms',
                label: 'sidebar.sms',
                role: ['admin'],
                product: 'component'
            }
        ]
    },
    {
        key: 'subscription',
        label: 'sidebar.subscriptions',
        leftIcon: 'ion-android-people',
        role: ['admin', 'merchant', 'operator', 'supervisor', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller', 'operator', 'supervisor'],
        product: 'subscription',
        children: [
            {
                key: 'subscription/subscription',
                label: 'sidebar.subscription',
                role: ['admin', 'merchant', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller', 'supervisor'],
                product: 'subscription',
            },
            {
                key: 'subscription/subscriber',
                label: 'sidebar.subscriber',
                role: ['admin', 'merchant', 'operator', 'supervisor', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller'],
                product: 'subscription',
            },
            {
                key: 'subscription/payment',
                label: 'sidebar.payment',
                role: ['admin', 'merchant', 'supervisor', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller'],
                product: 'subscription',
            },
            {
                key: 'subscription/stats',
                label: 'sidebar.stats',
                role: ['admin', 'merchant', 'supervisor', 'affiliate', 'merchant+teller', 'teller', 'affiliate+teller'],
                product: 'subscription',
            },
        ]
    },
    {
        key: 'shortener',
        label: 'sidebar.shortener',
        leftIcon: 'ion-android-people',
        role: ['admin']
    },
    {
        key: 'voicemail',
        label: 'sidebar.voicemail',
        leftIcon: 'ion-android-people',
        role: ['admin']
    },
    {
        key: 'Axialys',
        label: 'sidebar.axialys',
        leftIcon: 'ion-wifi',
        role: ['admin'],
        children: [
            {
                key: 'axialys/incalls',
                label: 'sidebar.incalls',
                role: ['admin']
            },
            {
                key: 'axialys/socket',
                label: 'sidebar.socket',
                role: ['admin']
            },
        ]
    },
    {
        key: 'Extracts',
        label: 'sidebar.extracts',
        leftIcon: 'ion-ios-download-outline',
        role: ['admin'],
        children: [
            {
                key: 'call/extract',
                label: 'sidebar.callExtract',
                role: ['admin']
            },
            {
                key: 'call/extractSimple',
                label: 'sidebar.callExtractSimple',
                role: ['admin']
            },
        ]
    },
    {
        key: 'voxpay',
        label: 'sidebar.voxpay',
        leftIcon: 'ion-clipboard',
        role: ['admin'],
        children: [
            {
                key: 'voxpay/payments',
                label: 'sidebar.payment',
                role: ['admin']
            },
            {
                key: 'voxpay/subscriptions',
                label: 'sidebar.subscriber',
                role: ['admin']
            },
            {
                key: 'voxpay/users',
                label: 'sidebar.users',
                role: ['admin']
            },
        ]
    },
    {
        key: 'search',
        label: 'sidebar.search',
        leftIcon: 'ion-ios-search',
        role: ['admin', 'external', 'operator', 'supervisor']
    },
    {
        key: 'control',
        label: 'sidebar.control',
        leftIcon: 'ion-ios-search',
        role: ['supervisor', 'admin']
    },
    {
        key: 'repositories',
        label: 'sidebar.repositories',
        leftIcon: 'ion-ios-search',
        role: ['admin'],
        children: [
            {
                key: 'repositories/rightManagement',
                label: 'sidebar.rightManagement',
                role: ['admin'],
            },
            {
                key: 'repositories/zipcodes',
                label: 'sidebar.zipcodes',
                role: ['admin'],
            },
            {
                key: 'repositories/botPkf',
                label: 'sidebar.botPkf',
                role: ['admin'],
            },
        ]
    },
    {
        key: 'voyancetellerbox',
        label: 'voyance-tellerbox',
        leftIcon: 'ion-eye',
        role: ['admin', 'merchant+teller', 'teller', 'operator+teller', 'affiliate+teller']
    },
    {
        key: 'voyance',
        label: 'sidebar.fortuneTelling',
        leftIcon: 'ion-eye',
        role: ['admin', 'merchant+teller', 'teller']
    },
    {
        key: 'voyance-realtime',
        label: 'sidebar.fortuneTellingRealTime',
        leftIcon: 'ion-eye',
        role: ['admin', 'merchant+teller', 'teller'],
        children: [
            {
                key: 'voyance/sub/onglet1?path=/kiosqueur/stats/salon/tabulaire/customer/' + localStorage.getItem('tellerbox.client'),
                label: 'Tabulaire',
                role: ['admin', 'merchant+teller', 'teller'],
            },
            {
                key: 'voyance/sub/onglet2?path=/kiosqueur/stats/salon/' + localStorage.getItem('tellerbox.client'),
                label: 'Salon',
                role: ['admin', 'merchant+teller', 'teller'],
            }
        ],
    },
    {
        key: 'voyance-stat',
        label: 'sidebar.fortuneTellingStats',
        leftIcon: 'ion-eye',
        role: ['admin', 'merchant+teller', 'teller'],
        children: [
            {
                key: 'voyance/sub/onglet4?path=/callcenter/' + localStorage.getItem('tellerbox.client') + '/stats/consultation',
                label: 'Callcenter',
                role: ['admin', 'merchant+teller', 'teller'],
            },
            {
                key: 'voyance/sub/onglet5?path=/kiosqueur/stats/paliers/' + localStorage.getItem('tellerbox.client'),
                label: 'sidebar.floors',
                role: ['admin', 'merchant+teller', 'teller'],
            },
            {
                key: 'voyance/sub/onglet6?path=/kiosqueur/statistiques/service/' + localStorage.getItem('tellerbox.client'),
                label: 'title.service',
                role: ['admin', 'merchant+teller', 'teller'],
            },
            {
                key: 'voyance/sub/onglet7?path=/kiosqueur/stats/services/all/' + localStorage.getItem('tellerbox.client'),
                label: 'button.alls',
                role: ['admin', 'merchant+teller', 'teller', 'affiliate+teller'],
            },
            {
                key: 'voyance/sub/onglet8?path=/kiosqueur/stats/sortante/sortante-service/' + localStorage.getItem('tellerbox.client'),
                label: 'Sortante',
                role: ['admin', 'merchant+teller', 'teller'],
            },
            {
                key: 'voyance/sub/onglet9?path=/kiosqueur/stats/sortante/global/' + localStorage.getItem('tellerbox.client'),
                label: 'Globale',
                role: ['admin', 'merchant+teller', 'teller'],
            },
            {
                key: 'voyance/sub/onglet10?path=/kiosqueur/liste/appelants/' + localStorage.getItem('tellerbox.client'),
                label: 'Appelants',
                role: ['admin', 'merchant+teller', 'teller'],
            }
        ],
    },
    {
        key: 'voyance/sub/onglet11?path=/kiosqueur/liste/services/' + localStorage.getItem('tellerbox.client'),
        label: 'sidebar.serviceList',
        leftIcon: 'ion-eye',
        role: ['admin', 'merchant+teller', 'teller']
    },
    {
        key: 'voyance-cabinet',
        label: 'sidebar.cabinet',
        leftIcon: 'ion-eye',
        role: ['admin', 'merchant+teller', 'teller'],
        children: [
            {
                key: 'voyance/sub/onglet12?path=/platform/' + localStorage.getItem('tellerbox.platform'),
                label: 'sidebar.platform',
                role: ['admin', 'merchant+teller', 'teller'],
            },
            {
                key: 'voyance/sub/onglet13?path=/platform/' + localStorage.getItem('tellerbox.platform') + '/voice-announcement',
                label: 'sidebar.advert',
                role: ['admin', 'merchant+teller', 'teller'],
            },
            {
                key: 'voyance/sub/onglet14?path=/callcenter/platform/planning/' + localStorage.getItem('tellerbox.platform'),
                label: 'sidebar.planning',
                role: ['admin', 'merchant+teller', 'teller'],
            },
            {
                key: 'voyance/sub/onglet15?path=/kiosqueur/teller/stats/tellers/platform/' + localStorage.getItem('tellerbox.platform'),
                label: 'sidebar.voyanceStats',
                role: ['admin', 'merchant+teller', 'teller'],
            },
        ],
    },
    {
        key: 'voyance/sub/onglet16?path=/teller/single/platform/' + localStorage.getItem('tellerbox.platform'),
        label: 'sidebar.planning',
        leftIcon: 'calendar',
        role: ['operator+teller']
    },
    {
        key: 'voyance/sub/onglet17?path=/kiosqueur/teller/stats/my-stats/' + localStorage.getItem('tellerbox.platform') + '/redirect',
        label: 'sidebar.voyanceStats',
        role: ['operator+teller'],
    },
    {
        key: 'voyance/sub/onglet2?path=/kiosqueur/stats/salon/' + localStorage.getItem('tellerbox.client'),
        label: 'sidebar.voyanceConnection',
        role: ['operator+teller'],
    },
    {
        key: 'voyance2',
        label: 'sidebar.fortuneTelling',
        leftIcon: 'ion-eye',
        role: ['admin', 'affiliate+teller'],
        children: [
            {
                key: 'voyance/sub/onglet5?path=/kiosqueur/stats/paliers/' + localStorage.getItem('tellerbox.client'),
                label: 'sidebar.floors',
                role: ['affiliate+teller'],
            },
            {
                key: 'voyance/sub/onglet6?path=/kiosqueur/statistiques/service/' + localStorage.getItem('tellerbox.client'),
                label: 'title.service',
                role: ['affiliate+teller'],
            },
            {
                key: 'voyance/sub/onglet7?path=/kiosqueur/stats/services/all/' + localStorage.getItem('tellerbox.client'),
                label: 'button.alls',
                role: ['affiliate+teller'],
            },
        ]
    },

    {
        key: 'voyance/sub/onglet18?path=/enregistrement/' + localStorage.getItem('tellerbox.platform'),
        label: 'sidebar.records',
        role: ['admin', 'merchant+teller', 'teller'],
    },
    {
        key: 'marketplace',
        label: 'sidebar.marketplace',
        leftIcon: 'ion-eye',
        role: ['admin', 'merchant+teller', 'teller'],
        children: [
            {
                key: 'marketplace/stats?path=kiosqueur/teller/stats/synergy/customer/' + localStorage.getItem('tellerbox.client'),
                label: 'sidebar.stats',
                role: ['admin', 'merchant+teller', 'teller'],
            },
            {
                key: 'marketplace/planning?path=marketplace/planning/' + localStorage.getItem('tellerbox.client'),
                label: 'sidebar.planning',
                role: ['admin', 'merchant+teller', 'teller'],
            },
        ]
    },
    {
        key: 'technicalError',
        label: 'sidebar.fatalError',
        leftIcon: 'ion-close',
        role: ['admin'],
    },

];
export default options;
