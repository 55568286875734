import config, {
  getCurrentLanguage
} from '../../containers/LanguageSwitcher/config';
import AppLocale from '../../languageProvider';
import actions from './actions';

const browserLanguage = navigator.language.split(/[-_]/)[0];
const langConfig = ((Object.keys(AppLocale).includes(browserLanguage)) ? getCurrentLanguage(browserLanguage):getCurrentLanguage(config.defaultLanguage || 'english'));

const initState = {
  isActivated: false,
  language: langConfig,
  messages:AppLocale[langConfig.locale]
};

export default function(state = initState, action) {
  switch (action.type) {
    case actions.ACTIVATE_LANG_MODAL:
      return {
        ...state,
        isActivated: !state.isActivated
      };
    case actions.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language,
        messages: action.messages
      };
    default:
      return state;
  }
}
