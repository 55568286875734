import md5 from 'js-md5';
class Gravatar {

    static url(email, size, rating) {
        let lowerCaseEmail = email.toLowerCase();
        let hash = md5(lowerCaseEmail);
        let defaultImage = 'retro';
        let url =
            'https://www.gravatar.com/avatar/' +
            hash +
            '?s=' +
            size +
            '&r=' +
            rating +
            '&d=' +
            defaultImage;

        return url;
    }
}

export default Gravatar;