import antdSA from 'antd/lib/locale-provider/fr_FR';
//import appLocaleData from 'react-intl/locale-data/fr';
import defaultMessages from '../locales/theme/fr_FR.json';
import leadMessages from '../locales/lead/fr_FR.json';
import sidebarMessages from '../locales/menu/fr_FR.json';
import subscriptionMessages from '../locales/subscription/fr_FR.json';
import scenarioMessages from '../locales/scenario/fr_FR.json';
import merMessages from '../locales/mer/fr_FR.json';
import skytrustMessages from '../locales/skytrust/fr_FR.json';

const saLang = {
  messages: {
    ...defaultMessages,
    ...leadMessages,
    ...sidebarMessages,
    ...subscriptionMessages,
    ...scenarioMessages,
    ...merMessages,
    ...skytrustMessages,
  },
  antd: antdSA,
  locale: 'fr-FR',
  //data: appLocaleData
};
export default saLang;
