export default {
  apiUrl: process.env.REACT_APP_API_URL,
  gcfUrl: process.env.REACT_APP_GCF_URL,
  googleClientId: process.env.GOOGLE_AUTH_CLIENT_ID,
  apolloUrl: process.env.REACT_APP_APOLLO_URL,
  smsPlusUrl: process.env.REACT_APP_SMS_PLUS_URL,
  skyrefUrl: process.env.REACT_APP_SKYREF_URL,
  version: process.env.REACT_APP_VERSION
};
const siteConfig = {
  siteName: 'Skycall',
  siteIcon: 'ion-flash',
  footerText: 'Skycall ©2022 Created by Skycall'
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};
const language = 'english';
const AlgoliaSearchConfig = {
  appId: '',
  apiKey: ''
};


const googleConfig = {
  apiKey: '' //
};
const mapboxConfig = {
  tileLayer: '',
  maxZoom: '',
  defaultZoom: '',
  center: []
};
export {
  siteConfig,
  themeConfig,
  language,
  AlgoliaSearchConfig,
  googleConfig,
  mapboxConfig,
}
