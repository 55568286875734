import React from 'react';
import {Avatar} from 'antd';
import GravatarService from '../service/gravatar';

class Gravatar extends React.Component {
    state = {
        url: '',
        alt: '',
    };

    UNSAFE_componentWillMount() {
        this.loadGravatar(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.loadGravatar(nextProps);
        }
    }

    loadGravatar = props => {
        //const lowerCaseEmail = props.email.toLowerCase();
        const size = props.size ? props.size : 200;
        const rating = props.rating ? props.rating : 'g';
        const alt = props.alt ? props.alt : 'Gravatar Image';
        let url = GravatarService.url(props.email,size,rating);

        this.setState({ url, alt });
    };

    render() {
        return <Avatar size={this.props.displaySize} src={this.state.url} />;
    }
}

export default Gravatar;