import {Map} from 'immutable';
import moment from 'moment';
import Axios from 'axios';
import Backend from '../customApp/service/backend/backend';
import GA4React from 'ga-4-react';

export function clearToken() {
    localStorage.clear();
}

export function getToken() {
    try {
        if (localStorage.getItem('google_payload')) {
            const backend = new Backend()
            const payload = JSON.parse(localStorage.getItem('google_payload'))
            backend.setStorage(payload)
            localStorage.setItem('id_token', payload.token)
        }
        const idToken = localStorage.getItem('id_token');
        return new Map({idToken});
    } catch (err) {
        clearToken();
        return new Map();
    }
}

export function timeDifference(givenTime) {
    givenTime = new Date(givenTime);
    const milliseconds = new Date().getTime() - givenTime.getTime();
    const numberEnding = number => {
        return number > 1 ? 's' : '';
    };
    const number = num => (num > 9 ? '' + num : '0' + num);
    const getTime = () => {
        let temp = Math.floor(milliseconds / 1000);
        const years = Math.floor(temp / 31536000);
        if (years) {
            const month = number(givenTime.getUTCMonth() + 1);
            const day = number(givenTime.getUTCDate());
            const year = givenTime.getUTCFullYear() % 100;
            return `${day}-${month}-${year}`;
        }
        const days = Math.floor((temp %= 31536000) / 86400);
        if (days) {
            if (days < 28) {
                return days + ' day' + numberEnding(days);
            } else {
                const months = [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec'
                ];
                const month = months[givenTime.getUTCMonth()];
                const day = number(givenTime.getUTCDate());
                return `${day} ${month}`;
            }
        }
        const hours = Math.floor((temp %= 86400) / 3600);
        if (hours) {
            return `${hours} hour${numberEnding(hours)} ago`;
        }
        const minutes = Math.floor((temp %= 3600) / 60);
        if (minutes) {
            return `${minutes} minute${numberEnding(minutes)} ago`;
        }
        return 'a few seconds ago';
    };
    return getTime();
}

export function stringToInt(value, defValue = 0) {
    if (!value) {
        return 0;
    } else if (!isNaN(value)) {
        return parseInt(value, 10);
    }
    return defValue;
}

export function stringToPosetiveInt(value, defValue = 0) {
    const val = stringToInt(value, defValue);
    return val > -1 ? val : defValue;
}

export function formatFloorAmount(number, nbDecimal = 3) {
    return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    }).format(Math.floor(number * Math.pow(10, nbDecimal)) / Math.pow(10, nbDecimal));
}

export function formatRoundAmount(number, nbDecimal = 3) {
    return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    }).format(number.toFixed(nbDecimal));
}

export function formatRoundNumber(number, nbDecimal = 3) {
    return new Intl.NumberFormat('fr-FR').format(number.toFixed(nbDecimal));
}

export function formatTMC(duration) {
    if (duration == 0) {
        return '00:00'
    } else {
        return Math.floor(duration / 60).toString().padStart(2, '0') + ':' + (duration % 60).toFixed().padStart(2, '0')
    }

}

export function formatDuration(duration) {
    if (duration === 0) {
        return '00:00'
    } else {
        return Math.floor(duration / 3600).toString().padStart(2, '0') + ':' + Math.floor((duration % 3600) / 60).toString().padStart(2, '0') + ':' + ((duration % 3600) % 60).toFixed().padStart(2, '0')
    }

}

export function tableSorter(field) {
    return {
        sorter: (a, b) => {
            const regExpDate = /^20\d{2}-[0-1]{1}\d{1}-[0-3]{1}\d{1}/gi
            const regExpNumberFormat = new RegExp("^[0-9 €.,]+$");

            if (Object.prototype.hasOwnProperty.call(a, field) && Object.prototype.hasOwnProperty.call(b, field) && a[field] !== null && b[field] !== null) {
                if (!isNaN(a[field]) && !isNaN(b[field])) {
                    //console.log(field, a[field], regExpDate.test(a[field]))
                    return a[field] - b[field];
                } else if (regExpNumberFormat.test(a[field]) && regExpNumberFormat.test(b[field])) {
                    return Number(a[field].replace('[ €]', '').replace(',', '.')) - Number(b[field].replace('[ €]', '').replace(',', '.'));
                } else if (regExpDate.test(a[field])) {
                    return moment(a[field]).valueOf() - moment(b[field]).valueOf();
                } else if (a[field]) {
                    return a[field].toString().localeCompare(b[field]);
                } else {
                    return 0
                }
            } else if (Object.prototype.hasOwnProperty.call(a, field) && a[field] !== null) {
                return 1;
            } else if (Object.prototype.hasOwnProperty.call(b, field) && b[field] !== null) {
                return -1;
            } else {
                return 0;
            }
        }
    }

}

// util function to convert the input to string type
function convertToString(input) {

    if (input) {

        if (typeof input === "string") {

            return input;
        }

        return String(input);
    }
    return '';
}


// convert string to words
function toWords(input) {

    input = convertToString(input);

    var regex = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;

    return input.match(regex);

}


// convert the input array to camel case
function toCamelCase(inputArray) {

    let result = "";

    for (let i = 0, len = inputArray.length; i < len; i++) {

        let currentStr = inputArray[i];

        let tempStr = currentStr.toLowerCase();

        if (i !== 0) {

            // convert first letter to upper case (the word is in lowercase)
            tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);

        }

        result += tempStr;

    }

    return result;
}


// this function call all other functions

export function toCamelCaseString(input) {

    let words = toWords(input);

    return toCamelCase(words);

}

export function getHomePage() {
    switch (localStorage.getItem('role')) {
        case 'operator':
            return "/private/operator/activity";

        case 'supervisor':
            return "/private/";

        case 'analyst':
            return "/private/stats";

        case 'teller':
            return "/private/voyance/sub/onglet1";

        case 'operator+teller':
            return "/private/voyance/sub/onglet16";
        //console.log("http://embedded.dialmanager.fr/auth?token=" + localStorage.getItem('tellerbox.token') + "&redirect_to=http://localhost:3000/private/voyance/sub/onglet16")
        //window.location.href="http://embedded.dialmanager.fr/auth?token=" + localStorage.getItem('tellerbox.token') + "&redirect_to=http://localhost:3000/private/voyance/sub/onglet16";


        case 'affiliate+teller':
            return "/private/voyance/sub/onglet7?path=/kiosqueur/stats/services/all/" + localStorage.getItem('tellerbox.client');


        default:
            return "/private/";
    }
}

export function rowsToCsv(rows) {
    const cols = Object.entries(rows[0]).map(r => r[0])
    if (cols.includes('__v')) {
        delete cols[cols.indexOf('__v')]
    }
    if (cols.includes('key')) {
        delete cols[cols.indexOf('key')]
    }
    return cols.map(r => '"' + r + '"').join(';') + '\n' + rows.map(r => {
        return cols.map(key => {
            if (r[key] && Array.isArray(r[key])) {
                return '"' + r[key].length + '"'
            } else if (r[key] && typeof r[key] === 'object' && r[key].name) {
                return '"' + r[key].name + '"'
            } else if (r[key] && typeof r[key] === 'object' && r[key].number) {
                return '"' + r[key].number + '"'
            } else if (r[key] && typeof r[key] === 'object' && r[key]._id) {
                return '"' + r[key]._id + '"';
            } else if (r[key] && key === 'content') {
                let indexMsg = r[key].conversation.findIndex((conv, index) => {
                    if (conv && conv.message) {
                        if (conv.message.startsWith("Merci d'avoir patienté")) {
                            return index;
                        }
                    }
                });
                if (indexMsg > -1) {
                    indexMsg++;
                    return `${r[key].conversation[indexMsg]?.message || ''}`;
                } else {
                    return "";
                }
            } else if (r[key] && typeof r[key] === 'object') {
                return '"' + escape(r[key].toString()) + '"'
            } else if (r[key]) {
                // console.log((/[\"]+/).test(r[key]), r[key])
                return '"' + ((/["]+/).test(r[key]) ? escape(r[key]) : r[key]) + '"'
            } else {
                return '"-"'
            }
        }).join(';')
    }).join('\n')
}

export async function searchCity(searchText) {
    if (/\d{4,}/ig.test(searchText) || /[a-zA-Z\s]{3,}/ig.test(searchText)) {
        const ga4 = GA4React.getGA4React()
        if (ga4) {
            ga4.gtag('event', 'axios', {
                'event_category': 'external',
                'event_label': 'searchCity'
            })
        }
        return Axios.get('https://datanova.laposte.fr/api/records/1.0/search/?dataset=laposte_hexasmal&q=' + searchText.replace("'", " ") + '&rows=20').then(responseRequest => {
            if (responseRequest.status === 200 && responseRequest.data.nhits > 0) {
                responseRequest.data.records.sort((a, b) => {
                    const aKey = a.fields.libelle_d_acheminement + ' - ' + a.fields.code_postal;
                    const bKey = b.fields.libelle_d_acheminement + ' - ' + b.fields.code_postal;
                    if (aKey < bKey) {
                        return -1;
                    } else if (aKey > bKey) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                return responseRequest.data.records
            }
        });
    } else {
        return []
    }
}

/**
 *
 * @param {String} content
 * @param {Object} call
 * @param {Object} subscriber
 * @returns
 */
export function replaceSmsTags(content, call, subscriber, lead) {
    let toReturn = content
    if (lead) {
        toReturn = toReturn.replace('#LEAD_PAGE#', lead.urlToUse)
            .replace('#LEAD_URL#', lead.url)
    }
    if (subscriber) {
        toReturn = ((subscriber.subscription.public_subscriber_root_url && subscriber.is_valid) ? toReturn.replace('#SUBSCRIBER_URL#', subscriber.subscription.public_subscriber_root_url
            .replace('#SUBSCRIBER_ID#', subscriber._id)) : toReturn)
            .replace('#CLIENT_SPACE_URL#', subscriber.subscription.client_space_url);
    }

    if (call.response) {
        toReturn = toReturn.replace('#SKYSEARCH_NAME#', call.response.name)
            .replace('#SKYSEARCH_ADDRESS#', call.response.address.street + ' ' + call.response.address.zipcode + ' ' + call.response.address.city)
            .replace('#SKYSEARCH_STREET#', call.response.address.street)
            .replace('#SKYSEARCH_ZIPCODE#', call.response.address.zipcode)
            .replace('#SKYSEARCH_CITY#', call.response.address.city)
            .replace('#SKYSEARCH_PHONE#', call.response.phone)
            .replace('#SKYSEARCH_OPENING_DAYS#', call.response.opening_days)
            .replace('#SKYSEARCH_OPENING_TIME#', call.response.opening_time)
    }

    if (call) {
        toReturn = toReturn.replace('#NUMBER#', (call.number) ? call.number.replace(/^33(C)?/i, '0') : '')
            .replace('#WEBSITE#', call?.parentCall?.website || call.website)
            .replace('#KEYWORD#', call?.parentCall?.keyword || call.keyword)
            .replace('#CALLID#', call?.parentCall?._id || call._id)
    }
    return toReturn
}

export function getLeafNodes(nodes, result = [], path = []) {
    for (let i = 0, length = nodes.length; i < length; i++) {
        const newPath = [...new Set(path)]
        if (!nodes[i].children || nodes[i].children.length === 0) {
            if (typeof nodes[i] === 'object' && nodes[i] !== null) {
                nodes[i].path = newPath.concat(nodes[i])
                result.push(nodes[i]);
            }


        } else {
            if (typeof nodes[i] === 'object' && nodes[i] !== null) {
                newPath.push(nodes[i])
            }
            result = getLeafNodes(nodes[i].children, result, newPath);
        }
    }
    return result;
}

export function getLeafPath(leaf, nodes, full = false) {
    const path = getLeafNodes(nodes).filter(p => p.value === leaf).shift().path
    return (full) ? path : path.map(p => p.value)
}

export function generateApiKey() {
    const length = 20
    const valuesPossible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_'
    let result = ''
    for (var i = 0; i < length; i++) {
        result += valuesPossible.charAt(Math.floor(Math.random() * 100) % valuesPossible.length);
    }
    return result;
}

export function syntaxHighlight(json) {
    if (typeof json != 'string') {
        json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });


}

export function jsonToGraphQLParam(obj) {
    //var cleaned = JSON.stringify(obj, null, 2);

    //return cleaned.replace(/^[\t ]*"[^:\n\r]+(?<!\\)":/gm, function (match) {
    //return match.replace(/"/g, "");
    //});
    return obj
}
