import { lazy } from 'react';

const routes = [
  {
    path: 'realtime',
    component: lazy(() => import('./containers/realtime'))
  },
  {
    path: 'server',
    component: lazy(() => import('./containers/Server/'))
  },
  {
    path: 'service',
    component: lazy(() => import('./containers/Service/'))
  },
  {
    path: 'service/create',
    component: lazy(() => import('./containers/Service/create.js'))
  },
  {
    path: 'service/massCreate',
    component: lazy(() => import('./containers/Service/massCreate.js'))
  },
  {
    path: 'service/massReaffect',
    component: lazy(() => import('./containers/Service/massReaffect.js'))
  },
  {
    path: 'service/tts',
    component: lazy(() => import('./containers/Service/tts'))
  },
  {
    path: 'operatorsStats/agents',
    component: lazy(() => import('./containers/OperatorsStats/AgentsStatsScreen'))
  },
  {
    path: 'operatorsStats/supervisors',
    component: lazy(() => import('./containers/OperatorsStats/SupervisorsStatsScreen'))
  },
  {
    path: 'call/tag',
    component: lazy(() => import('./containers/Call/tag.js'))
  },
  {
    path: 'call/tag/stats',
    component: lazy(() => import('./containers/Call/statsTag'))
  },
  {
    path: 'application',
    component: lazy(() => import('./containers/Service/application.js'))
  },
  {
    path: 'message',
    component: lazy(() => import('./containers/Message/'))
  },
  {
    path: 'message/create',
    component: lazy(() => import('./containers/Message/create.js'))
  },
  {
    path: 'office',
    component: lazy(() => import('./containers/Office/'))
  },
  {
    path: 'office/create',
    component: lazy(() => import('./containers/Office/create.js'))
  },
  {
    path: 'office/update/:officeid',
    component: lazy(() => import('./containers/Office/create.js'))
  },
  {
    path: 'stats',
    component: lazy(() => import('./containers/Stats/daily'))
  },
  /*{
    path: 'operators',
    component: lazy(() => import('./containers/Operators/'))
  },*/
  {
    path: 'affiliate/stats',
    component: lazy(() => import('./containers/Affiliates/stats'))
  },
  {
    path: 'affiliates/list',
    component: lazy(() => import('./containers/Affiliates/'))
  },
  {
    path: 'affiliates/company/:accountid',
    component: lazy(() => import('./containers/Affiliates/company.js'))
  },
  {
    path: 'affiliates/keyword',
    component: lazy(() => import('./containers/Affiliates/keyword.js'))
  },
  {
    path: 'lead/stats',
    component: lazy(() => import('./containers/lead/StatsScreen'))
  },
  {
    path: 'lead/statistics',
    component: lazy(() => import('./containers/lead/statsDirect'))
  },
  {
    path: 'lead/',
    component: lazy(() => import('./containers/lead'))
  },
  {
    path: 'lead/advertiser',
    component: lazy(() => import('./containers/lead/advertiser'))
  },
  {
    path: 'lead/offer',
    component: lazy(() => import('./containers/lead/offer'))
  },
  {
    path: 'lead/publisher',
    component: lazy(() => import('./containers/lead/publisher'))
  },
  {
    path: 'lead/broker',
    component: lazy(() => import('./containers/lead/broker'))
  },
  {
    path: 'lead/session/:sessionId',
    component: lazy(() => import('./containers/lead/session'))
  },
  {
    path: 'lead/create',
    component: lazy(() => import('./containers/lead/createLead'))
  },
  {
    path: 'lead/trackingAdjust',
    component: lazy(() => import('./containers/lead/trackingAdjust'))
  },
  {
    path: 'lead/trackingTradeDoubler',
    component: lazy(() => import('./containers/lead/TradeDoublerTrackScreen'))
  },
  {
    path: 'lead/algorithm',
    component: lazy(() => import('./containers/lead/algorithm'))
  },
  {
    path: 'call',
    component: lazy(() => import('./containers/Call/'))
  },
  {
    path: 'call/extract',
    component: lazy(() => import('./containers/Call/extract'))
  },
  {
    path: 'call/extractSimple',
    component: lazy(() => import('./containers/Call/extractSimple'))
  },
  {
    path: 'operator/activity',
    component: lazy(() => import('./containers/Activity/'))
  },
  {
    path: 'operator/activity/call',
    component: lazy(() => import('./containers/Activity/call'))
  },
  {
    path: 'account',
    component: lazy(() => import('./containers/Account/'))
  },
  {
    path: 'account/create',
    component: lazy(() => import('./containers/Account/createUser.js'))
  },
  {
    path: 'account/update/:accountId',
    component: lazy(() => import('./containers/Account/createUser.js'))
  },
  {
    path: 'users',
    component: lazy(() => import('./containers/Account/users'))
  },
  {
    path: 'chargeback',
    component: lazy(() => import('./containers/Chargeback/'))
  },
  {
    path: 'carrierPayouts',
    component: lazy(() => import('./containers/CarrierPayouts/'))
  },
  {
    path: 'carrierIr',
    component: lazy(() => import('./containers/CarrierIr/'))
  },
  {
    path: 'chargebackAxialys',
    component: lazy(() => import('./containers/CarrierIr/chargebackAxialys.js'))
  },
  {
    path: 'carrierPayouts/create/:carrier',
    component: lazy(() => import('./containers/CarrierPayouts/createPayout'))
  },
  {
    path: 'carrierOutcallFee',
    component: lazy(() => import('./containers/CarrierOutcallFee/'))
  },
  {
    path: 'carrierOutcallFee/create/:carrier',
    component: lazy(() => import('./containers/CarrierOutcallFee/createOutcallFee'))
  },
  {
    path: 'companies/',
    component: lazy(() => import('./containers/Companies/'))
  },
  {
    path: 'companies/:companyId',
    component: lazy(() => import('./containers/Companies/'))
  },
  {
    path: 'companies/createFee/:company',
    component: lazy(() => import('./containers/Companies/createFee.js'))
  },
  {
    path: 'companies/createPayout/:company',
    component: lazy(() => import('./containers/Companies/createPayout.js'))
  },
  {
    path: 'companies/createOutcallFee/:company',
    component: lazy(() => import('./containers/Companies/createOutcallFee.js'))
  },
  {
    path: 'companies/createVoucher/:company',
    component: lazy(() => import('./containers/Companies/createVoucher.js'))
  },
  {
    path: 'missingServices',
    component: lazy(() => import('./containers/MissingServices/'))
  },
  {
    path: 'notBilled',
    component: lazy(() => import('./containers/NotBilled/'))
  },
  {
    path: 'margin',
    component: lazy(() => import('./containers/Margin/'))
  },
  {
    path: 'marginOutcall',
    component: lazy(() => import('./containers/Margin/marginOutcall'))
  },
  {
    path: 'margin/affiliates',
    component: lazy(() => import('./containers/Margin/affiliates'))
  },
  {
    path: 'companyInvoices',
    component: lazy(() => import('./containers/CompanyInvoices/'))
  },
  {
    path: 'invoices',
    component: lazy(() => import('./containers/Invoice/'))
  },
  {
    path: 'invoices/create',
    component: lazy(() => import('./containers/Invoice/createInvoice'))
  },
  {
    path: 'invoices/createAdvancePayout',
    component: lazy(() => import('./containers/Invoice/createAdvancePayout'))
  },
  {
    path: 'invoices/view/:invoiceId',
    component: lazy(() => import('./containers/Invoice/singleInvoice')),
  },
  {
    path: 'scenarios/items',
    component: lazy(() => import('./containers/Scenarios/item'))
  },
  {
    path: 'skysearch',
    component: lazy(() => import('./containers/skysearch'))
  },
  {
    path: 'skysearch/import',
    component: lazy(() => import('./containers/skysearch/import'))
  },
  {
    path: 'scenarios/templates',
    component: lazy(() => import('./containers/Scenarios/template'))
  },
  {
    path: 'scenarios/services',
    component: lazy(() => import('./containers/Scenarios/service'))
  },
  {
    path: 'scenarios/stats',
    component: lazy(() => import('./containers/Scenarios/stats'))
  },
  {
    path: 'scenarios/injector',
    component: lazy(() => import('./containers/Scenarios/injector'))
  },
  {
    path: 'scenario/create/service/',
    component: lazy(() => import('./containers/Scenarios/createService'))
  },
  {
    path: 'scenario/update/service/:scenarioServiceId',
    component: lazy(() => import('./containers/Scenarios/createService'))
  },
  {
    path: 'scenario/create/template/',
    component: lazy(() => import('./containers/Scenarios/createTemplate'))
  },
  {
    path: 'scenario/update/template/:scenarioTemplateId',
    component: lazy(() => import('./containers/Scenarios/createTemplate'))
  },
  {
    path: 'skytrust/form',
    component: lazy(() => import('./containers/Skytrust/form'))
  },
  {
    path: 'skytrust/theme',
    component: lazy(() => import('./containers/Skytrust/theme'))
  },
  {
    path: 'skytrust/answer',
    component: lazy(() => import('./containers/Skytrust/answer'))
  },
  {
    path: 'skytrust/stats',
    component: lazy(() => import('./containers/Skytrust/stats'))
  },
  {
    path: 'mer/pool/',
    component: lazy(() => import('./containers/mer/pool'))
  },
  {
    path: 'mer/booking/',
    component: lazy(() => import('./containers/mer/booking'))
  },
  {
    path: 'mer/booking/stats',
    component: lazy(() => import('./containers/mer/stats'))
  },
  {
    path: 'mer/booking/errors',
    component: lazy(() => import('./containers/mer/errors'))
  },
  {
    path: 'mer/create/pool/',
    component: lazy(() => import('./containers/mer/createPool'))
  },
  {
    path: 'mer/update/pool/:poolId',
    component: lazy(() => import('./containers/mer/createPool'))
  },
  {
    path: 'component/supplier',
    component: lazy(() => import('./containers/Components/suppliers'))
  },
  {
    path: 'component/companyFee',
    component: lazy(() => import('./containers/Components/companyFees'))
  },
  {
    path: 'component/chatbot',
    component: lazy(() => import('./containers/Components/chatbots'))
  },
  {
    path: 'component/email',
    component: lazy(() => import('./containers/Components/emails'))
  },
  {
    path: 'component/sms',
    component: lazy(() => import('./containers/Components/sms'))
  },
  {
    path: 'component/create/supplier/',
    component: lazy(() => import('./containers/Components/createSupplier'))
  },
  {
    path: 'component/create/companyFee/',
    component: lazy(() => import('./containers/Components/createCompanyFee'))
  },
  {
    path: 'github',
    component: lazy(() => import('./containers/GithubSearch/'))
  },
  {
    path: 'timeline',
    component: lazy(() => import('./containers/Stats/timeline'))
  },
  {
    path: 'affiliate/stats/keyword',
    component: lazy(() => import('./containers/Stats/keyword'))
  },
  {
    path: 'affiliate/stats/theme',
    component: lazy(() => import('./containers/Stats/theme'))
  },
  {
    path: 'affiliate/stats/remainder',
    component: lazy(() => import('./containers/Stats/remainder'))
  },
  {
    path: 'axialys/incalls',
    component: lazy(() => import('./containers/Axialys/incalls'))
  },
  {
    path: 'axialys/socket',
    component: lazy(() => import('./containers/Axialys/socket'))
  },
  {
    path: 'voxpay/payments',
    component: lazy(() => import('./containers/Voxpay/payments'))
  },
  {
    path: 'voxpay/subscriptions',
    component: lazy(() => import('./containers/Voxpay/subscriptions'))
  },
  {
    path: 'voxpay/users',
    component: lazy(() => import('./containers/Voxpay/users'))
  },
  {
    path: 'search',
    component: lazy(() => import('./containers/Activity/search'))
  },
  {
    path: 'voicemail',
    component: lazy(() => import('./containers/Call/voicemail'))
  },
  {
    path: 'repositories/zipcodes',
    component: lazy(() => import('./containers/Repositories/zipcode'))
  },
  {
    path: 'repositories/rightManagement',
    component: lazy(() => import('./containers/Repositories/rightManagement'))
  },
  {
    path: 'repositories/botPkf',
    component: lazy(() => import('./containers/Repositories/botPkf'))
  },
  {
    path: 'voyance',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet1',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet2',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet4',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet5',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet6',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet7',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet8',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet9',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet10',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet11',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet12',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet13',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet14',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet15',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet16',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet17',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet18',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'voyance/sub/onglet18',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'marketplace/stats',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'marketplace/planning',
    component: lazy(() => import('./containers/Samipbx'))
  },
  {
    path: 'control/',
    component: lazy(() => import('./containers/Control'))
  },
  {
    path: 'subscription/subscription',
    component: lazy(() => import('./containers/Subscription/subscription'))
  },
  {
    path: 'subscription/subscriber',
    component: lazy(() => import('./containers/Subscription/subscriber'))
  },
  {
    path: 'subscription/payment',
    component: lazy(() => import('./containers/Subscription/payment'))
  },
  {
    path: 'subscription/stats',
    component: lazy(() => import('./containers/Subscription/stats'))
  },
  {
    path: 'subscription/create/subscriber',
    component: lazy(() => import('./containers/Subscription/createSubscriber'))
  },
  {
    path: 'subscription/update/subscriber/:subscriberId',
    component: lazy(() => import('./containers/Subscription/createSubscriber'))
  },
  {
    path: 'subscription/create/payment',
    component: lazy(() => import('./containers/Subscription/createPayment'))
  },
  {
    path: 'shortener',
    component: lazy(() => import('./containers/Shortener'))
  },
  {
    path: 'technicalError',
    component: lazy(() => import('../containers/Page/error')),
    exact: true
  },
  {
    path: '',
    component: lazy(() => import('./containers/Dashboard')),
    exact: true
  },
];
export default routes;
