import React from "react";
import { Route, Redirect, BrowserRouter as Router, Switch } from "react-router-dom";
import history from "./history";
import { connect } from "react-redux";
import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";
import { getToken } from "./helpers/utility";
import GA4React from 'ga-4-react';

const RestrictedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const ga4 = GA4React.getGA4React()
      if (ga4) {
        ga4.pageview(props.location.pathname, props.location, props.location.pathname);
      }

      return getToken()?.get('idToken') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location }
          }}
        />
      )
    }

    }
  />
);
const PublicRoutes = ({ isLoggedIn }) => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={"/"}
          component={asyncComponent(() => import("./containers/Page/signin"))}
        />
        <Route
          exact
          path={"/403"}
          component={asyncComponent(() => import("./containers/Page/403"))}
        />
        <Route
          exact
          path={"/404"}
          component={asyncComponent(() => import("./containers/Page/404"))}
        />
        <Route
          exact
          path={"/500"}
          component={asyncComponent(() => import("./containers/Page/500"))}
        />
        <Route
          exact
          path={"/signin"}
          component={asyncComponent(() => import("./containers/Page/signin"))}
        />
        <Route
          exact
          path={"/resetCache"}
          component={asyncComponent(() => import("./containers/Page/resetCache"))}
        />

        <Route
          exact
          path={"/forgotpassword"}
          component={asyncComponent(() =>
            import("./containers/Page/forgotPassword")
          )}
        />
        <Route
          exact
          path={"/resetpassword/:token"}
          component={asyncComponent(() =>
            import("./containers/Page/resetPassword")
          )}
        />
        <Route
          exact
          path={"/error"}
          component={asyncComponent(() =>
            import("./containers/Page/error")
          )}
        />

        <RestrictedRoute
          path="/private"
          component={App}
          isLoggedIn={isLoggedIn}
        />
      </Switch>
    </Router>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null
}))(PublicRoutes);
