import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import options from "./options";
import Scrollbars from "../../components/utility/customScrollBar.js";
import Menu from "../../components/uielements/menu";
import IntlMessages from "../../components/utility/intlMessages";
import SidebarWrapper from "./sidebar.style";
import appActions from "../../redux/app/actions";
import Logo from "../../components/utility/logo";
import { Select } from 'antd';

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const { Option } = Select;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed
} = appActions;
const stripTrailingSlash = str => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }
  handleClick (e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange (newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = key => {
    const map = {
      sub3: ["sub2"]
    };
    return map[key] || [];
  };

  handleChange = event => {
    let tellerbox = JSON.parse(localStorage.getItem('tellerbox'));

    localStorage.setItem('tellerbox.default', tellerbox.platform[event].name);
    localStorage.setItem('tellerbox.client', tellerbox.platform[event].data.client_id);
    localStorage.setItem('tellerbox.platform', tellerbox.platform[event].data.platform_id);
    localStorage.setItem('tellerbox.callcenter', tellerbox.platform[event].data.callcenter_id);

    window.location.reload(true);

  }
  getMenuItem = ({ singleOption, submenuColor }) => {
    const { key, label, leftIcon, children, role, product } = singleOption;
    const url = stripTrailingSlash(this.props.url);
    const tellerbox = JSON.parse(localStorage.getItem('tellerbox'));

    if (tellerbox && tellerbox.platform.length > 1
      && role !== undefined
      && role.includes(localStorage.getItem('role')?.toString())
      && label === 'voyance-tellerbox') {
      let opts = [];
      let defaultValue = localStorage.getItem('tellerbox.default', tellerbox.platform[0].name);

      for (var i = 0; i < tellerbox.platform.length; i++) {
        opts.push(<Option key={i}>{tellerbox.platform[i].name}</Option>);
      }

      return (
        <Menu.Item style={{ height: '80px' }} key={"Voyance-selectbox"}>
          <Select
            showSearch
            className={"tellerboxselect"}
            onChange={this.handleChange}
            defaultValue={defaultValue}
            style={{ width: '100%' }}
            optionFilterProp="children"
          >
            {opts}
          </Select>
        </Menu.Item>
      )
    }

    if (label === 'voyance-tellerbox') {
      return;
    }

    if (children) {
      return (
        ((role && role.includes(localStorage.getItem('role')?.toString())) &&
          (product === undefined ||
            (product && localStorage.getItem('products')?.split(',')?.includes(product)) ||
            (Array.isArray(product) && localStorage.getItem('products')?.split(',')?.filter(x => product.includes(x)).length > 0)
          )
        ) ?
          <SubMenu
            key={key}
            title={
              <span className="isoMenuHolder" >
                <i className={leftIcon} />
                <span className="nav-text">
                  <IntlMessages id={label} />
                </span>
              </span>
            }
          >
            {children.map(child => {
              if (
                (child.role && child.role.includes(localStorage.getItem('role')?.toString())) &&
                (child.product === undefined ||
                  (child.product && localStorage.getItem('products').split(',').includes(child.product)) ||
                  (Array.isArray(child.product) && localStorage.getItem('products').split(',').filter(x => child.product.includes(x)).length > 0)
                )
              ) {
                const linkTo = child.withoutDashboard
                  ? `/${child.key}`
                  : `${url}/${child.key}`;
                return (
                  <Menu.Item key={child.key}>
                    <Link to={linkTo}>
                      <IntlMessages id={child.label} />
                    </Link>
                  </Menu.Item>
                );
              } else {
                return null
              }

            })}
          </SubMenu> : ''
      );
    }
    return (
      (
        (role === undefined || role.includes(localStorage.getItem('role')?.toString())) &&
        (product === undefined ||
          (product && localStorage.getItem('products').split(',').includes(product)) ||
          (Array.isArray(product) && localStorage.getItem('products').split(',').filter(x => product.includes(x)).length > 0)
        )
      ) ?

        <Menu.Item key={key}>
          <Link to={`${url}/${key}`}>
            <span className="isoMenuHolder" style={submenuColor}>
              <i className={leftIcon} />
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          </Link>
        </Menu.Item> : ''
    );
  };
  render () {
    const { app, toggleOpenDrawer, customizedTheme, height } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";
    const onMouseEnter = () => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const styling = {
      backgroundColor: customizedTheme.backgroundColor
    };
    const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.3)",
      color: customizedTheme.textColor
    };
    const submenuColor = {
      color: "white"
    };

    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {options.map(singleOption =>
                this.getMenuItem({ submenuStyle, submenuColor, singleOption })
              )}

            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  state => ({
    app: state.App,
    customizedTheme: state.ThemeSwitcher.sidebarTheme,
    height: state.App.height
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Sidebar);
