import React from 'react';
import ReactDOM from 'react-dom';
import DashApp from './dashApp';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import GA4React from "ga-4-react";
import { clearToken } from './helpers/utility'



(async () => {
  const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS);
  await ga4react.initialize();
  ReactDOM.render(<DashApp />, document.getElementById('root'));
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// Auto reload app when new version is available
// Process is run in a web worker to keep it running even when app crash
if (window.Worker) {
  const autoReloadWorker = new Worker(process.env.PUBLIC_URL + "/sw.js?version=" + process.env.REACT_APP_VERSION);
  autoReloadWorker.onmessage = function (event) {
    if (event.data.type === "NEW_VERSION") {
      clearToken();
      window.location.href = "/";
      console.log(`New version available(${event.data.version}), reload app`)
    }
  };
}
