import Backend from '../backend/backend'
export default class Component extends Backend {
  constructor() {
    super()
    this.switchToGcfUrl()
  }

  async componentImport(params) {
    const headers = {}
    if (params.apiKey && params.apiKey.length > 1) {
      headers['x-api-key'] = params.apiKey
      delete params.apiKey
    }
    return this.request('post', 'componentImport/', params, headers).then(res => {
      return res.data
    })
  }
}
