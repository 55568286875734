import React, { createRef } from "react";
import styled from "styled-components";
import { Alert } from "antd";
import BugButton from "../../customApp/components/bugButton";

class RouteErrorBoundary extends React.Component {
  state = { error: null, errorInfo: null }
  constructor(props) {
    super(props);
    this.mainRef = createRef()
  }

  componentDidCatch (error, errorInfo) {
    this.setState({
      error: error.message,
      errorInfo: errorInfo,
    });
  }

  render () {
    if (this.state.error) {
      return (
        <Wrapper >
          <Alert
            type="error"
            message={<Title style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5em' }}>
              <img src="/images/error-icon.png" height="60px" alt="Error icon" />
              <span style={{ marginLeft: '10px' }}>Erreur</span>
            </Title>}
            description={
              <div ref={this.mainRef} style={{ textAlign: 'center' }}>
                <Message>
                  Oups, la page que vous essayez de consulter ne peut pas être affichée.<br />
                  <strong>Veuillez réessayer plus tard ou aller sur une autre page.</strong><br /><br /><br />
                  <span style={{ fontSize: '0.9em' }}>Vous pouvez informer le propriétaire du site du problème en cliquant ici : </span>

                </Message>
                <BugButton payload={this.state} refToShoot={this.mainRef.current} title="Envoyer un rapport de Bug" />
              </div>
            }
          />
        </Wrapper>
      );
    }
    return this.props.children;
  }
}

const Wrapper = styled.div`
  margin: auto;
  max-width: 700px;
  padding: 48px 24px;
`;

const Title = styled.h1`
  font-size: 1.2em;
`;

const Message = styled.p`
  padding: 16px 0;
`;

export default RouteErrorBoundary;
