import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../image/favicon.png'
import full_logo from '../../image/SKYCALL-LOGOSOMBRE.png'

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/private">
              <img src={logo} style={{width:"80%"}} alt="Skycall logo"/>
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/private"><img src={full_logo} style={{width:"80%"}} alt="Skycall Logo"/></Link>
        </h3>
      )}
    </div>
  );
};
