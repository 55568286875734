import React, { useState } from 'react'
import { useScreenshot } from 'use-react-screenshot'
import { Button, Tooltip, Icon, Modal, Input } from 'antd'
import ComponentService from '../service/gcf/component';
import CdnService from '../service/gcf/cdn';
import Notification from '../../components/notification';
import { v4 as uuidv4 } from 'uuid';
import IntlMessages from '../../components/utility/intlMessages';

export default props => {
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(false)

  const [visible, setVisible] = useState(false)
  const sendEmail = (image, payload) => {
    setLoading(true)

    const cdnService = new CdnService()


    if (image) {
      return cdnService.createCdn({ contentType: 'image/png', fileType: 'png', file: image })
        .then(response => {
          if (response.status === 'ok') {
            return sendEmailComponent(payload, comment, response, setVisible, setLoading)
          }
        }).catch(err => {
          if (err.response) {
            console.log(err.response)
            Notification('error', 'Impossible to send Screenshot' + JSON.stringify(err.response.data))
          } else {
            console.log(err)
            Notification('error', 'Impossible to send Screenshot. ' + JSON.stringify(err.message))
          }
        }).finally(() => setLoading(false))
    } else {
      return sendEmailComponent(payload, comment, {}, setVisible, setLoading).finally(() => setLoading(false))
    }



  }

  const [, takeScreenshot] = useScreenshot()
  const getImage = () => (props.refToShoot) ? takeScreenshot(props.refToShoot) : Promise.resolve(null)
  //console.log(props.refToShoot)
  const handleCancel = () => {
    setVisible(false)
  }
  const handleOk = () => {
    return getImage().then(data => sendEmail(data, props.payload))
  }
  return (
    <div>
      {(props.title) ? <Button onClick={() => {
        setVisible(true)

      }}>
        {props.title}
      </Button> : <Tooltip title={<IntlMessages id="activity.sendBugReport" />}>
        <Button onClick={() => {
          setVisible(true)

        }}>
          <Icon type="bug" />
        </Button>
      </Tooltip>}

      <Modal
        title={<IntlMessages id="activity.sendBugReport" />}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        width='35%'
        footer={[
          <Button key="enter" onClick={handleOk} type="primary" loading={loading}>
            <IntlMessages id="button.send" />
          </Button>,
          <Button key="back" onClick={handleCancel}>
            <IntlMessages id="button.cancel" />
          </Button>,
        ]}>
        <IntlMessages id="activity.bugReportComment" />
        <Input.TextArea onChange={(event) => {
          setComment(event.target.value)
        }} />
      </Modal>
    </div>
  )
}

function sendEmailComponent (payload, comment, cdn, setVisible, setLoading) {
  const profile = JSON.parse(localStorage.getItem('profile'))
  const cs = new ComponentService()
  return cs.componentImport({
    "component": "email",
    "payload": {
      "supplier_id": uuidv4(),
      "callId": payload?.call?._id,
      "to": { email: 'it@skycall.fr' },
      "sender": { email: profile.email },
      "replyTo": { email: profile.email },
      "subject": "Bug on page " + window.location.toString().split('?').shift(),
      "htmlContent": '<p>Hello</p><p>New bug sent by user ' + profile.login + ' on page ' + window.location.toString() + '</p>' + comment + '<p>User Info:<br/><pre>' + JSON.stringify(profile, null, 2) + '</pre></p><p>Payload:<br/><pre>' + JSON.stringify(payload, null, 2) + '</pre></p><p style="width:100%"><a href="' + (cdn.url || 'https://www.skycall.fr') + '">Screenshot</a>:<br/><img src="' + (cdn.url || 'no Picture') + '" alt="' + (cdn.url || 'no Picture') + '" style="width:100%"/></p>'
    }
  }).then(response => {
    if (response.status === 'ok') {
      Notification('success', 'Email sent');
      setVisible(false);
    } else {
      console.log(response);
      Notification('error', 'Impossible to send Email');
    }

    return Promise.resolve();
  }).catch(err => {
    if (err.response) {
      console.log(err.response);
      Notification('error', 'Impossible to send Email' + JSON.stringify(err.response.data));
    } else {
      console.log(err);
      Notification('error', 'Impossible to send Email. ' + JSON.stringify(err.message));
    }
    setLoading(false);
  });
}
